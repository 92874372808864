import {graphql, Link} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default ({data}: any) => (
    <Layout
        title="Realise the initial version of the software product – Step 3 of our approach."
        description="We design and develop the first viable version of the software product, all the way from scratch in a relatively short period."
        ogImage="product-realisation-en.png"
        translatedPagePath="/nl/werkwijze/productrealisatie/"
        locale={locale}>
        <Link to="/en/approach/prototyping/" className="body__nav body__nav--left" title="Approach step 2. Prototype">
            Previous step
        </Link>

        <Link
            to="/en/approach/service/"
            className="body__nav body__nav--right"
            title="Approach step 4A. Serving software">
            Next step
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>Product realisation</h2>
                        <div className="display__number">3</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            We go on to realise the product, starting with a clean slate. Using the knowledge and plans
                            obtained in the kick-off and concept phases, together we design and develop the first viable
                            version of the software product&mdash;all the way from scratch in a relatively short period.
                        </p>
                        <p>
                            Our team of specialists develops the product using agile methods. We do this in{' '}
                            <span className="highlight">iterations</span> of one or two weeks. Each iteration results in
                            a delivery that enables <span className="highlight">feedback</span> to be collected from
                            test users. <span className="highlight">New insights</span> are taken on board in
                            sub&shy;sequent iterations.
                        </p>
                        <p>
                            The <span className="highlight">product owner</span> continually determines what takes
                            priority, collects feedback and works closely with the team throughout the process.{' '}
                            <span className="highlight">Milestones</span> and corre&shy;sponding deadlines are
                            established in advance, to ensure that key over&shy;arching objectives are achieved in good
                            time.
                        </p>
                        <p>
                            After a number of iterations an initial version of the software product is{' '}
                            <span className="highlight">ready for launch</span>. We then immediately continue with
                            further development following the same iterative process, while at the same time constantly
                            monitoring the brand new product environment.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Iterations of 1 &ndash; 2 weeks</li>
                            <li className="ion-md-arrow-dropright">Interim milestones</li>
                            <li className="ion-md-arrow-dropright">4 &ndash; 12 months</li>
                            <li className="ion-md-arrow-dropright">2 &ndash; 4 specialists</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/en/approach/service/"
                            name="Next step: serving software"
                            title="Approach step 4A. serving software"
                        />
                    </div>
                </div>
            </article>

            <article className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Our <br /> technical <br /> expertise
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Creating high quality software products is closely linked with mastery of your chosen
                            technology.
                        </p>
                        <p>
                            To develop innovative and high quality <span className="highlight">web experiences</span>,
                            we are highly skilled in all aspects of what is known as the ‘web stack’. For example,
                            JavaScript components in the front end, MVC architecture in the back end, API definitions,
                            SQL queries and cloud infra&shy;structure. Depending on the project, we know what we need to
                            focus on.
                        </p>
                        <p>
                            Technology is never a limiting factor for us. Even if we don’t have any experience with a
                            particular technology, we ensure we master it quickly. Engineers are used to this &mdash;{' '}
                            <span className="highlight">lifelong learning</span> is part of the job.
                        </p>
                    </div>
                </div>

                <div className="container">
                    <div className="logos-grid logos-grid--tech">
                        <div className="logos-grid__background" />

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo React JS" fluid={data.react.childImageSharp.fluid} />
                                <span>React</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Typescript" fluid={data.typescript.childImageSharp.fluid} />
                                <span>Typescript</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Node JS" fluid={data.nodejs.childImageSharp.fluid} />
                                <span>Node.js</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Javascript" fluid={data.javascript.childImageSharp.fluid} />
                                <span>Javascript</span>
                            </div>
                        </div>

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo Laravel" fluid={data.laravel.childImageSharp.fluid} />
                                <span>Laravel</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo PHP" fluid={data.php.childImageSharp.fluid} />
                                <span>PHP</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Mysql" fluid={data.mysql.childImageSharp.fluid} />
                                <span>MySQL</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Postgresql" fluid={data.postgresql.childImageSharp.fluid} />
                                <span>PostgreSQL</span>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} locale={locale} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        laravel: file(relativePath: {eq: "logos/laravel.png"}) {
            ...logoImage
        }
        php: file(relativePath: {eq: "logos/php.png"}) {
            ...logoImage
        }
        mysql: file(relativePath: {eq: "logos/mysql.png"}) {
            ...logoImage
        }
        postgresql: file(relativePath: {eq: "logos/postgresql.png"}) {
            ...logoImage
        }
        javascript: file(relativePath: {eq: "logos/javascript.png"}) {
            ...logoImage
        }
        typescript: file(relativePath: {eq: "logos/typescript.png"}) {
            ...logoImage
        }
        react: file(relativePath: {eq: "logos/react.png"}) {
            ...logoImage
        }
        nodejs: file(relativePath: {eq: "logos/nodejs.png"}) {
            ...logoImage
        }
    }
`;
